import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { IconButtonComponent } from "./icon-button.component";

@NgModule({
	imports: [CommonModule, MatRippleModule, CommonIconModule],
	declarations: [IconButtonComponent],
	exports: [IconButtonComponent],
})
export class IconButtonModule {}
